
/* tslint:disable */
export const environment = {
  production: true,
  protocol: 'https',
  activeApi: 'https://apx.honcho.com.au/39.0.0',
  loggerApi: 'https://logger.honcho.be',
  ajaxTimeout: 25000,
  getIpAddress: 'https://jsonip.com/',
  serviceHost: 21,
  bing: {
    enabled: true,
    token: '5104100'
  },
  businessFeedRss: 'https://rss.honcho.com.au/',
  fullStory: {
    enabled: false,
    fsDebug: false,
    fsHost: 'fullstory.com',
    fsOrg: 'JRWHE',
    fsNamespace: 'FS',
  },
  luckyOrange: {
    enabled: true,
    site_id: 168251,
    source: 'https://d10lpsik1i8c69.cloudfront.net/w.js',
  },
  googleAdwords: {
    enabled: true,
    label: 'y2OXCLHttgEQl6Cc5QM',
    token: 'AW-1017581591',
  },
  googlePlaces: {
    key: 'AIzaSyAEq-0gxRBaj93VA0DBLyeFznAD4N99g5Y',
  },
  googleTagManager: {
    'trm': {
      tagManagerId: 'GTM-PD8F4QP',
    },
    analyticsId: 'UA-84542122-9',
    enabled: true,
    tagManagerId: 'GTM-PD8F4QP',
  },
  googleOptimize: {
    enabled: true,
    tagManagerId: 'GTM-WMNJRRR',
    optimizeId: 'GTM-NFPHZP2',
    analyticsId: 'UA-84542122-9',
  },
  honchoLink: 'https://honcho.com.au',
  livechat: {
    enabled: true,
    min: 0,
    max: 100000,
    license: 3817061,
  },
  logger: {
    enabled: false,
    activeFeLogger: 'https://apx.honcho.com.au/39.0.0/log/event',
    apiFeLogger: 'https://apx.honcho.com.au/39.0.0/log/api'
  },
  newRelic: {
    licenseKey: '',
    applicationId: '',
  },
  orderDetails: {
    tfn: {
      description: 'Australian Taxation Office Professional Agent Fee',
      gst: '6.27',
      period: '',
      price: '$69',
      serviceId: 4,
      title: 'Tax File Number Application',
    },
    abn: {
      description: 'Professional Tax Agent Service Fee',
      gst: '9.00',
      price: '99',
      serviceId: 2,
      serviceFeeId: 282,
      title: 'ABN Registration'
    },
    bn: {
      enabled: true,
      description: 'Professional ASIC Agent Service Fee & Australian Securities & Investments Commission Statutory Fee at $66.33 per year',
      period: {
        oneYear: {
          gst: '9.27',
          price: '139',
          serviceFeeId: 289,
          description: 'Professional ASIC Agent Service Fee including account management, application status alerts, issues handling and renewal notifications ($102) & Australian Securities & Investments Commission Statutory Fee ($37)'       
        },
        threeYears: {
          gst: '10.18',
          price: '199',
          serviceFeeId: 290,
          description: 'Professional ASIC Agent Service Fee including account management, application status alerts, issues handling and renewal notifications ($112) & Australian Securities & Investments Commission Statutory Fee ($87)'
        }
      },
      serviceId: 1,
      title: 'Business Name Registration:'
    },
    dn: {
      enabled: true,
      serviceId: 7,
      title: 'Domain Name Registration',
      description: 'Domain name registration services including account management and support services.',
      serviceFeeId: 265
    },
    gst: {
      description: 'Professional Tax Agent Service Fee.',
      gst: '4.45',
      price: '49',
      serviceId: 11,
      serviceFeeId: 225,
      title: 'GST Registration'
    },
    tm: {
      description: 'Professional service fee for single class Australian trademark application including account management, online and offline support, application processing and liaison with IP Australia, application status notifications and updates ($549) & Commonwealth Government statutory application fee ($250)',
      gst: '49.91',
      price: '799',
      serviceId: 27,
      serviceFeeId: 316,
      title: 'Trademark Registration',
      discountedGst: '31.73',
      discountedPrice: '599',
      discountedServiceFeeId:317,
      discountedDescription: 'Professional service fee for single class Australian trademark application including account management, online and offline support, application processing and liaison with IP Australia, application status notifications and updates ($349) & Commonwealth Government statutory application fee ($250)',
      addOns: {
        privacyProtection: {
          title: 'Privacy Protection',
          description: 'Annual protection of customer name and contact information from publicly accessible Australian Trademark Database',
          price: '125',
          gst: '11.36',
          serviceFeeId: 224,
          serviceId: 26,
          customServiceId: 118
        },
        amazonBrandRegistry: {
          title: 'Amazon Brand Registry',
          description: 'Professional Service Fee For Amazon Brand Registry Application',
          price: '150',
          gst: '13.64',
          serviceFeeId: 224,
          serviceId: 26,
          customServiceId: 117
        },
        expertDraft: {
          title: 'Expert Draft',
          description: 'Service Fee For Professional Application Review And Draft Creation',
          price: '350',
          gst: '31.82',
          serviceFeeId: 224,
          serviceId: 26,
          customServiceId: 116
        }
      }
    },
    cbd: {
      title: "CBD Address Subscription",
      description: "CBD Address Subscription",
      serviceId: 32,
      serviceFeeId: 323,
      gst: "4.45",
      price: "49",
      addOns: {
        extraLetter: {
          title: "Extra Letters",
          description: "Extra Letters",
          serviceId: 32,
          serviceFeeId: 324,
          gst: "2.73",
          price: "30"
        }
      }
    },
    gmb: {
      title: "Google Business Profile Registration",
      description: "Google Business Profile Registration",
      serviceId: 29,
      serviceFeeId: 330,
      gst: "8.09",
      price: "89"
    }
  },
  payment: {
    type: "subscription",
    defaultOptions: 'stripe-mastercard, stripe-visa, stripe-amex, bank, bpay',
    eway: {
      enabled: true,
      encryptionKey: 'pRKkToaOGZz+6EIhVXkPc6X9ma54TspXIivpE51zrYc/m8PCpOHf30cKNMT26yrjZgLvnBi1hHTWLrAiub8XrJCn8t9NivnFSs4ciOMQAsS00n6rYpeTIYDAiVO7NOEkJA2CRotoRrSvh+CwuKHs3Gr9E6sXpnbtPt4r20WB829itoeM0OepcvofxVHaHZRjT/as3anmW5JAZF+Kk6ckb0YOG3AeZGmd5/5TeUEayckfWH9c0ukAam8Eh4wxdKBqqZHqG8bkjhooIB3TgpsJ6xqnykhrqee3kD3tfZsHstQ9aa8lLlZ3zu3g2TBb3XsbxtQjRpjkPAsXs7nrr+lsRw==',
      forceFailed: false,
    },
    fallBackEnabled: true,
    fallBackOptions: 'eway-mastercard, eway-visa, eway-amex, bank, bpay',
    paypalExpress: {
      enabled: true,
      isLive: false,
    },
    recurly: {
      enabled: false,
      publicKey: 'sjc-Zv4OsgChTFdCMgB377n4mm',
    },
    redirect_timeout: 3000,
    stripe: {
      enabled: true,
      forceFailed: false,
      publicKey: 'pk_live_VnWqLR04LTcqkHRzBWDzMJfz',
    },
    templates: {
      summary: {
        'html': './order-summary-cbd.component.html',
        'css' : './order-summary-cbd.component.scss'
      },
      link: {
        'html': './payment-link-cbd.component.html',
        'css' : './payment-link-cbd.component.scss'
      },
      modal: {
        'html': './payment-modal-cbd.component.html',
        'css' : './payment-modal-cbd.component.scss'
      },
      form: {
        'html': './payment-form-cbd.component.html',
        'css' : './payment-form-cbd.component.scss'
      }
    }
  },
  forms: {
    associate_limit: 20,
  },
  enabledService: {
    bnm: true,
    dn: true,
    gst: true,
  },
  productServices: {
    gas: {
      service: 'gas',
      waivePayment: 'yes',
    },
    businessCard: {
      csa: 'csa',
      serviceFeeId: 224,
      applicationName: 'Business Card',
      customServiceId: 34,
      notes: '',
      description: '3 free designs. Upon design approval, $99 for 100 cards',
    },
    googleListing: {
      csa: 'csa',
      serviceFeeId: 224,
      applicationName: 'Google Listing',
      customServiceId: 35,
      notes: '',
      description: 'A business profile on Google My Business',
    },
    website: {
      csa: 'csa',
      serviceFeeId: 224,
      applicationName: 'Website',
      customServiceId: 36,
      notes: '',
      description: 'Website design and launch',
    }
  },
  seo: {
    enabled: true,
  },
  serviceHostId: 21,
  splitExperiment: {
    enabled: true,
    experimentId: 'mdlsamlfwq_fqm',
    variationId: '1',
  },
  staticValues: {
    contactNumber: '1300 320 283',
  },
  version: 'default',
  wsEnabled: true,
  wsNameSpace: '/abn',
  wsUrl: 'https://wsn.masterswitch.com.au',
};
/* tslint:enable */

import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { APP_BASE_HREF } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { RoutePreloaderComponent } from '@app/common/helper/route-preloader/route-preloader.component';
import { SupportedSite } from '@mng-reusable/core/enums/supported-site.enum';
import { SITE } from '@mng-reusable/core/tokens/site';
import { FeLoggerErrorHandler } from '@mng-reusable/logger/fe-logger/fe-logger-error-handler';
import { CHECKOUT_META_TAGS } from '@mng-reusable/payment/tokens/checkout-meta-tags';
import { TrackingDirectivesModule } from '@mng-reusable/tracking/directives/tracking-directives.module';
import { MatButtonModule} from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    RoutePreloaderComponent
  ],
  imports: [
    BrowserModule,
    MatButtonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TrackingDirectivesModule,
    MatMenuModule,
    MatIconModule
  ],
  exports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RoutePreloaderComponent
  ],
  providers: [
        CookieService,
    { provide: SITE, useValue: SupportedSite.gmbStandalone },
    { provide: ErrorHandler, useClass: FeLoggerErrorHandler },
    {
      provide: CHECKOUT_META_TAGS, useValue: {
        title: '',
        description: '',
        keywords: '',
      },
    },
    { provide: APP_BASE_HREF, useValue: '/' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export enum SupportedSite {
  abnStandalone = 'abn',
  bnmStandalone = 'bnm',
  honcho = 'hon',
  lightswitch = 'lsw',
  tfnStandalone = 'tfn',
  trmStandalone = 'trm',
  cbdStandalone = 'cbd',
  dnStandAlone = 'dn',
  gmbStandalone = 'gmb'
}

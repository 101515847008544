import {Component, OnInit, ViewEncapsulation, Input} from '@angular/core';
import { TrackingV2Service } from '@mng-reusable/tracking/services/tracking-v2.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  @Input() registerHeaderFlag:any;
  constructor(
  	private _trackingV2Service: TrackingV2Service,
  ) { }

  ngOnInit(): void {
    this._trackingV2Service.bindGAEvents('header');
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppCustomPreloader } from './app-custom-preloader';
import { URLS } from '@mng-reusable/core/constants/urls.constant';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('@app/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'register',
    loadChildren: () => import('@app/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'active-ping',
    loadChildren: () => import('@app/ping/ping.module').then(m => m.PingModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    preloadingStrategy: AppCustomPreloader,
  })],
  providers: [AppCustomPreloader],
  exports: [RouterModule]
})
export class AppRoutingModule{}

import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Action } from '@mng-reusable/core/action';
import { Store } from '@mng-reusable/core/store.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  private $ngDestroy: Subject<any> = new Subject<any>();
  @Input() hideMainFooter:any;
  yearDate:number;
  constructor(
  private _store: Store,
  private _changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    const date = new Date();
    this.yearDate = date.getFullYear();
    this._store.dispatch(new Action('UPDATE', { layoutHideFooter: true }));
  }

  ngOnDestroy(): void {
    this._changeDetectorRef.detach();
    this.$ngDestroy.next();
    this.$ngDestroy.complete();
  }
}

import { Component, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { GoogleTagManagerService } from "@mng-reusable/utility/google/google-tag-manager.service";
import { TrackingV3Service } from '@mng-reusable/tracking/services/tracking-v3.service';
import { URLS } from '@mng-reusable/core/constants/urls.constant';
import { Action } from '@mng-reusable/core/action';
import { Store } from '@mng-reusable/core/store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'gmb';
  showGlobalPreloader = false;
  hideFooter = false;
  trackCategory = '';
  registerHeader = false;

  constructor(
     private _store: Store,
     private _changeDetectorRef: ChangeDetectorRef,
     private _router: Router,
     private _googleTagManagerService: GoogleTagManagerService,
     private _trackingV3Service: TrackingV3Service,
  ) {}

  ngOnInit(): void {
    this._googleTagManagerService.initialize();
    this.initStoreEvents();
    this.initRouteEvents();
  }

  ngAfterViewInit(): void {
    this._changeDetectorRef.detectChanges();
  }

  private initStoreEvents(): void {
    this._store.select('layoutShowPreloader').subscribe((show: boolean) => {
      this.showGlobalPreloader = show;
    });

    this._store.select('layoutHideFooter').subscribe((hide: boolean) => {
      this.hideFooter = hide;
    });

    this._store.select('registerHeader').subscribe((hide: boolean) => {
      this.registerHeader = hide;
    });
  }

  private initRouteEvents(): void {
    this._router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        this.resetLayoutDefaults();
      }
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          this._trackingV3Service.initializeTrackingEvents(this.trackCategory);
          this._trackingV3Service.gtagPageLoad(event.urlAfterRedirects, this.trackCategory);
        }, 10);
        window.scrollTo(0, 0);

        if (event.url.indexOf(URLS.revisit) === -1) {
          setTimeout(() => { // wait for event category to take effect
            this._store.dispatch(new Action('UPDATE', { layoutShowPreloader: false }));
          }, 50);
        }
      }
    });

  }

  private resetLayoutDefaults(): void {
    this._store.dispatch(new Action('UPDATE', { layoutShowPreloader: false }));
    this._store.dispatch(new Action('UPDATE', { layoutHideFooter: true }));
    this._store.dispatch(new Action('UPDATE', { registerHeader: false }));
  }
}
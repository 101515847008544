<header class="header" xmlns="http://www.w3.org/1999/html">
	<div class="wrapper">
		<div class="logo-container">
			<div class="logo-wrapper" *ngIf="!registerHeaderFlag">
				<a href="https://abnregistration.com.au">
					<img src="/assets/images/main/abn3.png" class="logo" />
				</a>
			</div>

			<div class="logo-wrapper center" *ngIf="registerHeaderFlag">
				<a href="https://abnregistration.com.au">
					<img src="/assets/images/main/abn3.png" class="logo"/>
				</a>
			</div>
		</div>
		<div class="nav-container" *ngIf="!registerHeaderFlag">
			<a class="nav-link" href="https://abnregistration.com.au/learn-more"><span dirMngDefaultTracker="learn_more">Learn More</span></a>
			<a class="nav-link" href="https://abnregistration.com.au/contact-us"><span dirMngDefaultTracker="contact">Contact Us</span></a>
			<a class="nav-link phone" href="tel:1300320283">
				<img class="phone-icon" alt="" src="/assets/images/main/phone-icon.png">1300 320 283
			</a>
		</div>
		<div class="nav-mobile" *ngIf="!registerHeaderFlag">
			<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
				<mat-icon>view_headline</mat-icon>
			</button>
			<mat-menu #menu="matMenu" class="nav-menu-panel">
				<button mat-menu-item>
					<a class="nav-link" href="/learn-more"><span dirMngDefaultTracker="learn_more">Learn More</span></a>
				</button>
				<button mat-menu-item [routerLink]="['/contact-us']">
					<a class="nav-link" href="/contact-us"><span dirMngDefaultTracker="contact">Contact Us</span></a>
				</button>
				<button mat-menu-item>
					<a class="nav-phone" href="tel:1300320283">
						<img class="phone-icon" alt="" src="/assets/images/main/phone-icon.png" style="padding-right: 10px">
						<p>1300 320 283</p>
					</a>
				</button>
			</mat-menu>
		</div>
	</div>
</header>
